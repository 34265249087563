import React, { Component } from 'react';
import axios from 'axios';
import { animateScroll as scroll } from 'react-scroll';
import logo from './images/logo.png';
import vairak from './images/vairak.svg';
import './App.css';
import Form from './components/Form';
import Notification from './components/Notification';

class App extends Component {
  state = {
    products: [
      {
        id: 7,
        title: '3D paneļi',
        image: 'images/7.jpg',
        checked: false,
      },
      {
        id: 1,
        title: 'Akustiskie paneļi',
        image: 'images/1.jpg',
        checked: false,
      },
      {
        id: 2,
        title: 'Led apgaismojums',
        image: 'images/2.jpg',
        checked: false,
      },
      {
        id: 3,
        title: 'Mūsdienīgs stils',
        image: 'images/3.jpg',
        checked: false,
      },
      { id: 4, title: 'Klasisks stils', image: 'images/4.jpg', checked: false },
      { id: 5, title: 'Grīdlīstes', image: 'images/5.jpg', checked: false },
      { id: 6, title: 'Sienu rāmji', image: 'images/6.jpg', checked: false },
    ],
    vards: '',
    epasts: '',
    telefons: '',
    sending: false,
    responseMessage: '',
    notificationVisible: false,
    showMore: true,
  };

  resetForm = () => {
    const { products } = this.state;
    products.forEach((p, i) => (products[i].checked = false));
    this.setState({
      vards: '',
      epasts: '',
      telefons: '',
      notificationVisible: false,
      products,
      showMore: true,
    });
  };

  handleProductSelection = (e) => {
    const target = e.target;
    const value = target.checked;
    const name = target.name;
    const { products } = this.state;
    const id = products.findIndex((p) => p.id === Number(name));
    products[id].checked = value;

    this.setState({
      products,
    });
  };

  handleFormInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    const { products, vards, epasts, telefons } = this.state;
    const produkti = products
      .filter((p) => p.checked)
      .map((p) => ({ id: p.id, title: p.title }));
    const medus = e.target.adrese.value;
    if (!produkti.length || medus !== 'enterL1v1nG') {
      alert('Jūs neesat izvēlējies nevienu produktu. Droši, izvēlieties! :)');
    } else {
      const ajaxData = {
        vards,
        epasts,
        telefons,
        produkti,
        medus,
      };
      console.log(ajaxData);
      this.setState({ sending: true });
      axios
        .post('/', ajaxData)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              responseMessage: res.data.data.message,
              sending: false,
              notificationVisible: true,
            });
            scroll.scrollToTop({
              duration: 1000,
              delay: 100,
              smooth: 'easeInOutQuart',
              ignoreCancelEvents: true,
            });
            setTimeout(() => this.resetForm(), 3000);
          }
        })
        .catch((error) => {
          alert(`Diemžēl atgadījās kļūda: ${error.message}`);
          this.setState({
            error: true,
            sending: false,
          });
        });
    }
    e.preventDefault();
  };

  scrollToBottom = () => {
    scroll.scrollToBottom({
      duration: 1000,
      delay: 100,
      smooth: 'easeInOutQuart',
      ignoreCancelEvents: true,
    });
    this.setState({ showMore: false });
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const rootElement = document.getElementById('root');
    if (rootElement.getBoundingClientRect().top < -200) {
      this.setState({ showMore: false });
    }
  };

  render() {
    const {
      products,
      responseMessage,
      notificationVisible,
      sending,
      vards,
      epasts,
      telefons,
      showMore,
    } = this.state;
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <main className="App-main">
          <Form
            products={products}
            disabled={sending}
            vards={vards}
            epasts={epasts}
            telefons={telefons}
            onProductSelect={this.handleProductSelection}
            onInput={this.handleFormInput}
            onSubmit={this.handleSubmit}
          />
          <Notification text={responseMessage} visible={notificationVisible} />
        </main>
        <footer className="App-footer">
          <button
            className={
              showMore ? 'App-footer__more' : 'App-footer__more hidden'
            }
            onClick={this.scrollToBottom}
          >
            <img src={vairak} alt="Vairāk" />
          </button>
          <p>
            Pieprasot informāciju, apstiprinu, ka atļauju Enter Living apstrādāt
            manus datus
          </p>
        </footer>
      </div>
    );
  }
}

export default App;
