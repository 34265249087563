import React, { Component } from 'react';
import './Form.css';
import Product from './Product';

class Form extends Component {
  state = {
    medus: '',
  }

  handleBlur = e => {
    this.setState({ medus: 'enterL1v1nG' });
  }

  handleChange = e => {
    this.setState({ medus: e.target.value });
  }

  render() {
    const {
      products,
      onProductSelect,
      onInput,
      onSubmit,
      disabled,
      vards,
      epasts,
      telefons,
    } = this.props;
    return (
      <form className="Form" onSubmit={onSubmit}>
        <div className="Form-products">
          {
            products.map(product => (
              <Product
                key={product.id}
                onProductSelect={onProductSelect}
                {...product}
              />
            ))
          }
        </div>
        <div className="Form-fields">
          <h2>Saņemt informāciju</h2>
          <input
            className="Form-input"
            type="text"
            placeholder="Vārds *"
            name="vards"
            value={vards}
            onChange={onInput}
            required
            disabled={disabled}
          />
          <input
            className="Form-input"
            type="email"
            placeholder="E-pasts *"
            name="epasts"
            value={epasts}
            onChange={onInput}
            required
            disabled={disabled}
            onBlur={this.handleBlur}
          />
          <input
            className="Form-input"
            type="text"
            placeholder="Telefona nr."
            name="telefons"
            value={telefons}
            onChange={onInput}
            disabled={disabled}
          />
          <input
            className="Form-input"
            type="text"
            placeholder="Adrese"
            value={this.state.medus}
            name="adrese"
            onChange={this.handleChange}
            required
          />
          <button className="Form-button" type="submit" disabled={disabled}>{disabled ? 'Sūtu...' : 'Nosūtīt'}</button>
        </div>
      </form>
    );
  }
};

export default Form;