import React from 'react';
import './Notification.css';

const Notification = props => {
  const { text, visible } = props;
  const className = visible ? 'Notification visible' : 'Notification';
  return (
    <div className={className}>
      <img
        src="images/checkbox.svg"
        alt="X"
        className="Notification-icon"
      />
      <h4 className="Notification-text">{text}</h4>
    </div>
  );
};

export default Notification;