import React from 'react';
import './Product.css';

const Product = props => {
  const { title, id, checked, image, onProductSelect } = props;
  const className = checked ? 'Product selected' : 'Product';
  const style = {
    backgroundImage: `url(${image})`
  }
  return (
    <label className={className}>
      <div className="Product-image" style={style} />
      <input
        name={id}
        type="checkbox"
        checked={checked}
        onChange={onProductSelect}
      />
      <h2>{title}</h2>
      <img src="images/checkbox.svg" alt="X" className="Product-checkbox"/>
    </label>
  );
};

export default Product;